import { useMemo } from 'react';
import { QueryKey, useQueryClient, UseQueryOptions } from 'react-query';

import {
  RemoteDataError,
  useMutationWithRemoteDataError,
  useQueryWithRemoteDataError,
} from '../api/queries';
import {
  deleteAccount,
  deleteSupportAccessRight,
  deleteUserApplication,
  deleteUserEmail,
  deleteUserParticipation,
  fetchSupportAccessRights,
  fetchTOS,
  fetchUserApplications,
  fetchUserEmails,
  fetchUserIntegrations,
  fetchUserParticipations,
  fetchUserProfile,
  getInstallIntegration,
  patchUserEmail,
  patchUserParticipationDataAccessPermissions,
  patchUserProfile,
  postConnectIntegration,
  postDisconnectIntegration,
  postNewUserEmail,
  postTOS,
  putUserPassword,
} from '../api/queries/personal';
import {
  PostConnectIntegrationPayload,
  PostDisconnectIntegrationPayload,
  UserEmailUpdatePayload,
} from '../api/types';
import { logException } from '../common/sentry';
import {
  ParticipantDataPermissions,
  SupportAccessRight,
  UserAuthorizedApplication,
  UserEmail,
  UserIntegration,
  UserParticipation,
} from '../common/types';

export function useTOS() {
  return useQueryWithRemoteDataError(['tos'], fetchTOS);
}

export function useAcceptTOS() {
  const queryClient = useQueryClient();
  return useMutationWithRemoteDataError(postTOS, {
    onSuccess: (tosData) => {
      queryClient.setQueryData(['tos'], tosData);
    },
  });
}

export function useUser() {
  return useQueryWithRemoteDataError(['me'], fetchUserProfile);
}

export function useUserID() {
  const { data } = useUser();
  return data?.uid;
}

export function useUserEmail() {
  const { data } = useUser();
  return data?.email;
}

export function useUserHipaa() {
  const { data } = useUser();
  return data?.isHipaa;
}

export function useUserLocale() {
  const { data } = useUser();

  const locale = useMemo(
    () => ({
      units: data?.userInfo.units ?? 'metric',
    }),
    [data?.userInfo.units],
  );
  return locale;
}

export function useAnalyticsID() {
  const { data } = useUser();
  return data?.analyticsUID;
}

export function useModifyUserProfile() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    (payload: { name: string }) => patchUserProfile(payload),
    {
      onSuccess: (userProfile) => {
        queryClient.setQueryData(['me'], userProfile);
      },
    },
  );
}

export function useUserEmails() {
  return useQueryWithRemoteDataError(['me', 'email'], fetchUserEmails);
}

export function useCreateUserEmail() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    (payload: { email: string }) => postNewUserEmail(payload),
    {
      onSuccess: (userEmails) => {
        queryClient.setQueryData(['me', 'email'], userEmails);
      },
    },
  );
}

export function useModifyUserEmail() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    ({
      emailID,
      payload,
    }: {
      payload: UserEmailUpdatePayload;
      emailID: string;
    }) => patchUserEmail(emailID, payload),
    {
      onSuccess: () => {
        const key = ['me', 'email'];
        queryClient.invalidateQueries(key);
      },
    },
  );
}

export function useDeleteUserEmail() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    (emailID: string) => deleteUserEmail(emailID),
    {
      onSuccess: ({ emailID: eID }) => {
        const key = ['me', 'email'];
        const userEmailsCache = queryClient.getQueryData<UserEmail[]>(key);
        queryClient.setQueryData(
          key,
          userEmailsCache?.filter((e) => e.id !== eID),
        );
      },
    },
  );
}

export function useUserParticipations() {
  return useQueryWithRemoteDataError(
    ['me', 'participation'],
    fetchUserParticipations,
  );
}
export function useDeleteUserParticipation() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    (memberID: string) => deleteUserParticipation(memberID),
    {
      onSuccess: ({ memberID: mID }) => {
        const key = ['me', 'participation'];
        const userParticipationsCache =
          queryClient.getQueryData<UserParticipation[]>(key);
        queryClient.setQueryData(
          key,
          userParticipationsCache?.filter((e) => e.memberID !== mID),
        );
      },
    },
  );
}

export function useModifyUserParticipationDataSharingPermissions() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    ({
      memberID,
      payload,
    }: {
      memberID: string;
      payload: ParticipantDataPermissions;
    }) => patchUserParticipationDataAccessPermissions(memberID, payload),
    {
      onSuccess: ({ memberID: mID, dataPermissions }) => {
        const key = ['me', 'participation'];
        const userParticipationsCache =
          queryClient.getQueryData<UserParticipation[]>(key);
        queryClient.setQueryData(
          key,
          userParticipationsCache?.map((e) =>
            e.memberID !== mID ? e : { ...e, dataPermissions },
          ),
        );
      },
    },
  );
}

export function useUserAuthorizedApps() {
  return useQueryWithRemoteDataError(['me', 'apps'], fetchUserApplications);
}

export function useDeleteUserAuthorizedApp() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    (appID: string) => deleteUserApplication(appID),
    {
      onSuccess: ({ appID: aID }) => {
        const key = ['me', 'apps'];
        const userAppsCache =
          queryClient.getQueryData<UserAuthorizedApplication[]>(key);
        queryClient.setQueryData(
          key,
          userAppsCache?.filter((e) => e.id !== aID),
        );
      },
    },
  );
}

export function useUserIntegrations(
  config?: UseQueryOptions<
    UserIntegration[],
    RemoteDataError,
    UserIntegration[],
    QueryKey
  >,
) {
  return useQueryWithRemoteDataError(
    ['me', 'connections'],
    fetchUserIntegrations,
    config,
  );
}

export function useInstallIntegration(integration: string | undefined) {
  return useQueryWithRemoteDataError(['me', 'oauth', integration], () => {
    getInstallIntegration(integration);
  });
}

export function useConnectIntegration() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    (payload: PostConnectIntegrationPayload) => postConnectIntegration(payload),
    {
      onSuccess: () => {
        const key = ['me', 'connections'];
        queryClient.invalidateQueries(key);
      },
    },
  );
}

export function useDisconnectIntegration() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(
    (payload: PostDisconnectIntegrationPayload) =>
      postDisconnectIntegration(payload),
    {
      onSuccess: () => {
        const key = ['me', 'connections'];
        queryClient.invalidateQueries(key);
      },
    },
  );
}

export function useUserPasswordChange() {
  return useMutationWithRemoteDataError(
    (payload: { passwordOld: string; passwordNew: string }) =>
      putUserPassword({
        password_new: payload.passwordNew,
        password_old: payload.passwordOld,
      }),
  );
}

export function useSupportAccessRights() {
  return useQueryWithRemoteDataError(
    ['supportAccessRights'],
    fetchSupportAccessRights,
  );
}

export function useRevokeSupportAccessRight() {
  const queryClient = useQueryClient();

  return useMutationWithRemoteDataError(deleteSupportAccessRight, {
    onSuccess: ({ id }) => {
      const key = ['supportAccessRights'];
      const accessRights = queryClient.getQueryData<SupportAccessRight[]>(key);
      queryClient.setQueryData(
        key,
        accessRights?.filter((r) => r.id !== id),
      );
    },
    onError: (err) => {
      logException(err);
    },
  });
}

export function useDeleteAccount() {
  return useMutationWithRemoteDataError(deleteAccount);
}
