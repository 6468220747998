import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  QueryObserverResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
} from 'react-query';

import { clearCredentials } from '../auth';

/**
 * It is the responsibility of the data fetchers to also convert the data to
 * the type used in the UI.
 */

interface InvalidParams {
  in: string;
  name: string;
  value: string;
  reason: string;
}

export interface ServerError {
  status: number;
  title: string;
  detail?: string;
  invalid_params?: InvalidParams[];
}
export class RemoteDataError extends Error {
  constructor(
    message: string,
    public readonly uri: string,
    public readonly status: number,
    public readonly statusText: string,
    public readonly responseContent?: ServerError,
  ) {
    super(message);
  }
}

export type RemoteData<T> = QueryObserverResult<T, RemoteDataError>;

/**
 * The purpose of these wrapper functions is to define our Error type but not
 * have to define the response or payload types explicitly since they're already
 * defined in the query functions.
 */
export function useQueryWithRemoteDataError<T, QK extends QueryKey>(
  key: QK,
  fn: QueryFunction<T, QK>,
  config?: UseQueryOptions<T, RemoteDataError, T, QK>,
): RemoteData<T> {
  return useQuery(key, fn, config);
}

export function useMutationWithRemoteDataError<
  Response,
  Payload,
  Snapshot = unknown,
>(
  mutationFn: MutationFunction<Response, Payload>,
  config?: UseMutationOptions<Response, RemoteDataError, Payload, Snapshot>,
): UseMutationResult<Response, RemoteDataError, Payload, Snapshot> {
  return useMutation(mutationFn, config);
}

type HTTPMethod = 'GET' | 'PUT' | 'DELETE' | 'POST' | 'PATCH';

export async function doFetch<
  ApiResponseType,
  ReturnType = ApiResponseType,
  BodyPayload = undefined,
>({
  method,
  uri,
  payload,
  converter,
  customHeaders,
}: {
  method: HTTPMethod;
  uri: string;
  converter: (data: ApiResponseType) => ReturnType;
  customHeaders?: Array<[name: string, value: string]>;
  // It would be preferable to make this not optional so that the compiler tells
  // us if we forget to attach the payload to the call, but then we have to
  // explicitly call this with `payload: undefined` if we don't have a payload.
  // :sad_trombone:
  payload?: BodyPayload;
}): Promise<ReturnType> {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  // TODO: figure out why we have this
  headers.append('X-Requested-With', 'XmlHttpRequest');

  if (payload) {
    headers.append('Content-Type', 'application/json');
  }
  customHeaders?.forEach(([name, value]) => headers.append(name, value));

  const response = await fetch(uri, {
    method,
    headers,
    credentials: 'same-origin',
    body: payload && JSON.stringify(payload),
  });
  if (response.status === 401) {
    // Unauthorized. Not logged in or cookie expired.
    clearCredentials();

    // Redirect to login page
    window.location.href = `/user/sign-in?next=${encodeURIComponent(
      window.location.pathname + window.location.search,
    )}`;
    throw new RemoteDataError(
      'User not allowed to access resource. Logging out.',
      uri,
      response.status,
      response.statusText,
    );
  }

  if (!response.ok) {
    let responseContent;
    try {
      responseContent = await response.json();
    } catch {
      // Ignored, no content
    }
    throw new RemoteDataError(
      `Error while fetching ${uri}: ${response.status} ${response.statusText}`,
      uri,
      response.status,
      response.statusText,
      responseContent,
    );
  }

  const data: ApiResponseType =
    // 204 = No Response. ApiResponseType should expect {}
    response.status === 204 ? {} : await response.json();
  return converter(data);
}
